import { createGlobalStyle } from 'styled-components'
import reset from 'styled-reset'
import 'typeface-montserrat'
import 'typeface-arvo'
// import 'typeface-cambay'
import 'typeface-ubuntu-mono'

export const theme = {
  font: {
    sans: '"Montserrat", sans-serif',
    serif: 'Arvo, sans',
    monospace: '"Ubuntu Mono", monospace',
  },
  fontSize: {
    xs: '0.75rem',
    sm: '0.875rem',
    base: '1rem',
    lg: '1.125rem',
    xl: '1.25rem',
    '2xl': '1.5rem',
    '3xl': '1.875rem',
    '4xl': '2.25rem',
    '5xl': '3rem',
    '6xl': '4rem',
  },
  fontWeight: {
    hairline: '100',
    thin: '200',
    light: '300',
    normal: '400',
    medium: '500',
    semibold: '600',
    bold: '700',
    extrabold: '800',
    black: '900',
  },
}

export const GlobalStyle = createGlobalStyle`
  ${reset}

  body {
    font-family: ${({ theme }) => theme.font.sans};
    font-size: ${({ theme }) => theme.fontSize.base};
    line-height: 1.65;
    color: #373f49;
    background: #fff;
    margin: 0;
  }

  img {
    display: block;
    width: 100%;
  }

  h1,
  h2,
  h3 {
    font-size: ${({ theme }) => theme.fontSize['4xl']};
    font-weight: normal;
  }

  a {
    color: currentColor;
  }

  .wrapper {
    width: calc(100% - 10vmin);
    margin: 0 auto;
    padding: 5vmin 0;
  }

  /**
  * article grid
  */
  .article-list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 5vmin;
  }

  /**
  *
  */
  .section-headline {
    padding: 0 0 0.4em 0;
    margin: 0 0 5vmin 0;
    border-bottom: 1px solid #ddd;
  }

  /**
  *
  */
  .list-inline {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .list-inline li {
    display: inline-block;
  }
`
